<template>
  <div class="card-base base-graph company-details">
    <div class="card-head-bar with-bg">
      <div class="head-title">
        <h2>{{ address }}</h2>
      </div>
      <div class="head-toolbar">
        <contact-company />
      </div>
    </div>
    <div class="flex-container card-subheader">
      <div class="flex-items">{{ companyType }}</div>
      <div class="flex-items"><span class="shipment-label">Updated</span>: 2019-08-25</div>
    </div>
    <div class="tab bottom-shadow exp-1">
      <nuxt-link
        v-for="tab in tabsList"
        :to="getPath(tab)"
        :disabled="tab.disabled"
        :class="{
          active: tab.name === module,
          disabled: tab.disabled
        }"
        :key="tab.name">
        {{ tab.text }}
      </nuxt-link>
    </div>
    <div class="card-main block-section">
      <div class="company-details-row">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CountriesDropdown from '~/components/common/CountriesDropdown'
import ContactCompany from '~/components/company/ContactCompany'
import { buildURLParams, buildQueryParams } from '~/utils/url'

export default {
  components: {
    CountriesDropdown,
    ContactCompany
  },

  props: {
    module: {
      type: String,
      default: 'overview'
    }
  },
  computed: {
    ...mapState('company', {
      companyName: state => state.companyName,
      isImporter: state => state.isImporter,
      isSupplier: state => state.isSupplier
    }),
    ...mapState('company/about', ['address']),
    companyType() {
      if (this.isImporter && this.isSupplier) {
        return 'Supplier and Importer'
      }

      return this.isImporter ? 'Importer' : 'Supplier'
    },
    tabsList() {
      return [
        { text: 'Overview', name: 'overview', disabled: false },
        {
          text: 'Shipments',
          name: 'shipments',
          queryParams: { p: 1, r: 20 },
          disabled: false
        },
        {
          text: 'Importers',
          name: 'importers',
          queryParams: { p: 1, r: 20 },
          disabled: false
        },
        {
          text: 'Suppliers',
          name: 'suppliers',
          queryParams: { p: 1, r: 20 },
          disabled: false
        },
        { text: 'About Company', name: 'about', disabled: false },
        { text: 'Notes', name: 'notes', disabled: false }
      ].filter(
        item =>
          (item.name !== 'importers' && item.name !== 'suppliers') ||
          (item.name === 'importers' && this.isSupplier) ||
          (item.name === 'suppliers' && this.isImporter)
      )
    }
  },
  methods: {
    getPath({ name, queryParams } = {}) {
      const URLParams = buildURLParams(['company', this.$route.params.id, name])
      const URLQuery = buildQueryParams(queryParams)

      return URLParams + URLQuery
    }
  }
}
</script>
<style lang="scss" scoped>
h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 70%;
}
</style>
